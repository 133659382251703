import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="special-abilities-and-conditions"></a>
Special Abilities &amp; Conditions
    </h2><h3><a id="special-abilities"></a>
SPECIAL ABILITIES
    </h3>
    <p className="initial">
A special ability is either extraordinary, spell-like, or
supernatural in nature.
    </p>
    <p><a id="extraordinary-abilities"></a>
      <b>Extraordinary Abilities (Ex)</b>: Extraordinary abilities are
nonmagical. They are, however, not something that just anyone can
do or even learn to do without extensive training. Effects or
areas that negate or disrupt magic have no effect on extraordinary
abilities.
    </p>
    <p><a id="spell-like-abilities"></a>
      <b>Spell-Like Abilities (Sp)</b>: Spell-like abilities, as the
name implies, are spells and magical abilities that are very much
like spells. Spell-like abilities are subject to spell resistance
and dispel magic. They do not function in areas where magic is
suppressed or negated (such as an antimagic field).
    </p>
    <p><a id="supernatural-abilities"></a>
      <b>Supernatural Abilities (Su)</b>: Supernatural abilities are
magical but not spell-like. Supernatural abilities are not subject
to spell resistance and do not function in areas where magic is
suppressed or negated (such as an antimagic field). A supernatural
ability&rsquo;s effect cannot be dispelled and is not subject to
counterspells. See the table below for a summary of the types of
special abilities.
    </p>
    <p><a id="table-special-ability-types"></a>
      <b>Table: Special Ability Types</b>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>
          </th>
          <th>
Extraordinary
          </th>
          <th>
Spell-Like
          </th>
          <th>
Supernatural
          </th>
        </tr>
        <tr className="odd-row">
          <td>
Dispel
          </td>
          <td>
No
          </td>
          <td>
Yes
          </td>
          <td>
No
          </td>
        </tr>
        <tr>
          <td>
            <p>{`Spell resistance`}</p>
          </td>
          <td>
No
          </td>
          <td>
Yes
          </td>
          <td>
No
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Antimagic field
          </td>
          <td>
No
          </td>
          <td>
Yes
          </td>
          <td>
Yes
          </td>
        </tr>
        <tr>
          <td className="last-row">
Attack of opportunity
          </td>
          <td className="last-row">
No
          </td>
          <td className="last-row">
Yes
          </td>
          <td className="last-row">
No
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <i>Dispel</i>: Can dispel magic and similar spells dispel
the effects of abilities of that type?
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <i>Spell Resistance</i>: Does spell resistance protect a
creature from these abilities?
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <i>Antimagic Field</i>: Does an antimagic field or similar
magic suppress the ability?
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            <i>Attack of Opportunity</i>: Does using the ability provoke
attacks of opportunity the way that casting a spell does?
          </td>
        </tr>
      </tbody>
    </table>
    <h5><a id="ability-score-loss"></a>
ABILITY SCORE LOSS
    </h5>
    <p className="initial">
Various attacks cause ability score loss, either ability damage or
ability drain. Points lost to ability damage return at the rate of
1 point per day (or double that if the character gets complete bed
rest) to each damaged ability, and the spells lesser restoration
and restoration offset ability damage as well. Ability drain,
however, is permanent, though restoration can restore even those
lost ability score points.
    </p>
    <p>
While any loss is debilitating, losing all points in an ability
score can be devastating.
    </p>
    <ul>
      <li>
        <p>
Strength 0 means that the character cannot move at all. He
lies helpless on the ground.
        </p>
      </li>
      <li>
        <p>
Dexterity 0 means that the character cannot move at all. He
stands motionless, rigid, and helpless.
        </p>
      </li>
      <li>
        <p>
Constitution 0 means that the character is dead.
        </p>
      </li>
      <li>
        <p>
Intelligence 0 means that the character cannot think and is
unconscious in a coma-like stupor, helpless.
        </p>
      </li>
      <li>
        <p>
Wisdom 0 means that the character is withdrawn into a deep
sleep filled with nightmares, helpless.
        </p>
      </li>
      <li>
        <p>
Charisma 0 means that the character is withdrawn into a
catatonic, coma-like stupor, helpless.
        </p>
      </li>
    </ul>
    <p>
Keeping track of negative ability score points is never necessary.
A character&rsquo;s ability score can&rsquo;t drop below 0.
    </p>
    <p>
Having a score of 0 in an ability is different from having no
ability score whatsoever.
    </p>
    <p>
Some spells or abilities impose an effective ability score
reduction, which is different from ability score loss. Any such
reduction disappears at the end of the spell&rsquo;s or ability&rsquo;s
duration, and the ability score immediately returns to its former
value.
    </p>
    <p>
      <p>{`If a character`}{`’`}{`s Constitution score drops, then he loses 1 hit
point per Hit Die for every point by which his Constitution
modifier drops. A hit point score can`}{`’`}{`t be reduced by Constitution
damage or drain to less than 1 hit point per Hit Die.`}</p>
    </p>
    <p>
The ability that some creatures have to drain ability scores is a
supernatural one, requiring some sort of attack. Such creatures do
not drain abilities from enemies when the enemies strike them,
even with unarmed attacks or natural weapons.
    </p>
    <h5><a id="antimagic"></a>
ANTIMAGIC
    </h5>
    <p className="initial">
An <a style={{
        "color": "#579eb6"
      }} href="spellsAtoB.html#antimagic-field">antimagic field</a> spell or
effect cancels magic altogether. An antimagic effect has the
following powers and characteristics.
    </p>
    <ul>
      <li>
        <p>
No supernatural ability, spell-like ability, or spell works in
an area of antimagic (but extraordinary abilities still work).
        </p>
      </li>
      <li>
        <p>
Antimagic does not dispel magic; it suppresses it. Once a
magical effect is no longer affected by the antimagic (the
antimagic fades, the center of the effect moves away, and so
on), the magic returns. Spells that still have part of their
duration left begin functioning again, magic items are once
again useful, and so forth.
        </p>
      </li>
      <li>
        <p>
Spell areas that include both an antimagic area and a normal
area, but are not centered in the antimagic area, still
function in the normal area. If the spell&rsquo;s center is in the
antimagic area, then the spell is suppressed.
        </p>
      </li>
      <li>
        <p>
Golems and other constructs, elementals, outsiders, and
corporeal undead, still function in an antimagic area (though
the antimagic area suppresses their spellcasting and their
supernatural and spell-like abilities normally). If such
creatures are summoned or conjured, however, see below.
        </p>
      </li>
      <li>
        <p>
Summoned or conjured creatures of any type, as well as
incorporeal undead, wink out if they enter the area of an
antimagic effect. They reappear in the same spot once the
field goes away.
        </p>
      </li>
      <li>
        <p>
Magic items with continuous effects do not function in the
area of an antimagic effect, but their effects are not
canceled (so the contents of a bag of holding are unavailable,
but neither spill out nor disappear forever).
        </p>
      </li>
      <li>
        <p>
Two antimagic areas in the same place do not cancel each other
out, nor do they stack.
        </p>
      </li>
      <li>
        <p>
Wall of force, prismatic wall, and prismatic sphere are not
affected by antimagic. Break enchantment, dispel magic, and
greater dispel magic spells do not dispel antimagic. Mage&rsquo;s
disjunction has a 1% chance per caster level of destroying an
antimagic field. If the antimagic field survives the
disjunction, no items within it are disjoined.
        </p>
      </li>
    </ul>
    <h5><a id="blindsight"></a><a id="blindsight-and-blindsense"></a>
BLINDSIGHT AND BLINDSENSE
    </h5>
    <p className="initial">
Some creatures have blindsight, the extraordinary ability to use a
nonvisual sense (or a combination of such senses) to operate
effectively without vision. Such sense may include sensitivity to
vibrations, acute scent, keen hearing, or echolocation. This
ability makes invisibility and concealment (even magical darkness)
irrelevant to the creature (though it still can&rsquo;t see ethereal
creatures). This ability operates out to a range specified in the
creature description.
    </p>
    <ul>
      <li>
        <p>
Blindsight never allows a creature to distinguish color or
visual contrast. A creature cannot read with blindsight.
        </p>
      </li>
      <li>
        <p>
Blindsight does not subject a creature to gaze attacks (even
though darkvision does).
        </p>
      </li>
      <li>
        <p>
Blinding attacks do not penalize creatures using blindsight.
        </p>
      </li>
      <li>
        <p>
Deafening attacks thwart blindsight if it relies on hearing.
        </p>
      </li>
      <li>
        <p>
Blindsight works underwater but not in a vacuum.
        </p>
      </li>
      <li>
        <p>
Blindsight negates displacement and blur effects.
        </p>
      </li>
    </ul>
    <p><a id="blindsense"></a>
      <b>Blindsense</b>: Other creatures have blindsense, a lesser
ability that lets the creature notice things it cannot see, but
without the precision of blindsight. The creature with blindsense
usually does not need to make Spot or Listen checks to notice and
locate creatures within range of its blindsense ability, provided
that it has line of effect to that creature. Any opponent the
creature cannot see has total concealment (50% miss chance)
against the creature with blindsense, and the blindsensing
creature still has the normal miss chance when attacking foes that
have concealment. Visibility still affects the movement of a
creature with blindsense. A creature with blindsense is still
denied its Dexterity bonus to Armor Class against attacks from
creatures it cannot see.
    </p>
    <h5><a id="breath-weapon"></a>
BREATH WEAPON
    </h5>
    <p className="initial">
A creature attacking with a breath weapon is actually expelling
something from its mouth (rather than conjuring it by means of a
spell or some other magical effect). Most creatures with breath
weapons are limited to a number of uses per day or by a minimum
length of time that must pass between uses. Such creatures are
usually smart enough to save their breath weapon until they really
need it.
    </p>
    <ul>
      <li>
        <p>
Using a breath weapon is typically a standard action.
        </p>
      </li>
      <li>
        <p>
No attack roll is necessary. The breath simply fills its
stated area.
        </p>
      </li>
      <li>
        <p>
Any character caught in the area must make the appropriate
saving throw or suffer the breath weapon&rsquo;s full effect. In
many cases, a character who succeeds on his saving throw still
takes half damage or some other reduced effect.
        </p>
      </li>
      <li>
        <p>
Breath weapons are supernatural abilities except where noted.
        </p>
      </li>
      <li>
        <p>
Creatures are immune to their own breath weapons.
        </p>
      </li>
      <li>
        <p>
Creatures unable to breathe can still use breath weapons. (The
term is something of a misnomer.)
        </p>
      </li>
    </ul><a id="charm"></a><a id="charm-and-compulsion"></a>
    <h5>
CHARM AND COMPULSION
    </h5>
    <p className="initial">
Many abilities and spells can cloud the minds of characters and
monsters, leaving them unable to tell friend from foe &mdash; or worse
yet, deceiving them into thinking that their former friends are
now their worst enemies. Two general types of enchantments affect
characters and creatures: charms and compulsions.
    </p>
    <p>
Charming another creature gives the charming character the ability
to befriend and suggest courses of actions to his minion, but the
servitude is not absolute or mindless. Charms of this type include
the various charm spells. Essentially, a charmed character retains
free will but makes choices according to a skewed view of the
world.
    </p>
    <ul>
      <li>
        <p>
A charmed creature doesn&rsquo;t gain any magical ability to
understand his new friend&rsquo;s language.
        </p>
      </li>
      <li>
        <p>
A charmed character retains his original alignment and
allegiances, generally with the exception that he now regards
the charming creature as a dear friend and will give great
weight to his suggestions and directions.
        </p>
      </li>
      <li>
        <p>
A charmed character fights his former allies only if they
threaten his new friend, and even then he uses the least
lethal means at his disposal as long as these tactics show any
possibility of success (just as he would in a fight between
two actual friends).
        </p>
      </li>
      <li>
        <p>
A charmed character is entitled to an opposed Charisma check
against his master in order to resist instructions or commands
that would make him do something he wouldn&rsquo;t normally do even
for a close friend. If he succeeds, he decides not to go along
with that order but remains charmed.
        </p>
      </li>
      <li>
        <p>
A charmed character never obeys a command that is obviously
suicidal or grievously harmful to her.
        </p>
      </li>
      <li>
        <p>
If the charming creature commands his minion to do something
that the influenced character would be violently opposed to,
the subject may attempt a new saving throw to break free of
the influence altogether.
        </p>
      </li>
      <li>
        <p>
A charmed character who is openly attacked by the creature who
charmed him or by that creature&rsquo;s apparent allies is
automatically freed of the spell or effect.
        </p>
      </li>
    </ul>
    <p>
Compulsion is a different matter altogether. A compulsion
overrides the subject&rsquo;s free will in some way or simply changes
the way the subject&rsquo;s mind works. A charm makes the subject a
friend of the caster; a compulsion makes the subject obey the
caster.
    </p>
    <p>
Regardless of whether a character is charmed or compelled, he
won&rsquo;t volunteer information or tactics that his master doesn&rsquo;t ask
for.
    </p>
    <h5><a id="cold-immunity"></a>
COLD IMMUNITY
    </h5>
    <p className="initial">
A creature with cold immunity never takes cold damage. It has
vulnerability to fire, which means it takes half again as much
(+50%) damage as normal from fire, regardless of whether a saving
throw is allowed, or if the save is a success or failure.
    </p>
    <h5><a id="damage-reduction"></a>
DAMAGE REDUCTION
    </h5>
    <p className="initial">
Some magic creatures have the supernatural ability to instantly
heal damage from weapons or to ignore blows altogether as though
they were invulnerable.
    </p>
    <p>
The numerical part of a creature&rsquo;s damage reduction is the amount
of hit points the creature ignores from normal attacks. Usually, a
certain type of weapon can overcome this reduction. This
information is separated from the damage reduction number by a
slash. Damage reduction may be overcome by special materials, by
magic weapons (any weapon with a +1 or higher enhancement bonus,
not counting the enhancement from masterwork quality), certain
types of weapons (such as slashing or bludgeoning), and weapons
imbued with an alignment. If a dash follows the slash then the
damage reduction is effective against any attack that does not
ignore damage reduction.
    </p>
    <p>
Ammunition fired from a projectile weapon with an enhancement
bonus of +1 or higher is treated as a magic weapon for the purpose
of overcoming damage reduction. Similarly, ammunition fired from a
projectile weapon with an alignment gains the alignment of that
projectile weapon (in addition to any alignment it may already
have).
    </p>
    <p>
Whenever damage reduction completely negates the damage from an
attack, it also negates most special effects that accompany the
attack, such as injury type poison, a monk&rsquo;s stunning, and injury
type disease. Damage reduction does not negate touch attacks,
energy damage dealt along with an attack, or energy drains. Nor
does it affect poisons or diseases delivered by inhalation,
ingestion, or contact.
    </p>
    <p>
Attacks that deal no damage because of the target&rsquo;s damage
reduction do not disrupt spells.
    </p>
    <p>
Spells, spell-like abilities, and energy attacks (even nonmagical
fire) ignore damage reduction.
    </p>
    <p>
Sometimes damage reduction is instant healing. Sometimes damage
reduction represents the creature&rsquo;s tough hide or body. In either
case, characters can see that conventional attacks don&rsquo;t work.
    </p>
    <p>
If a creature has damage reduction from more than one source, the
two forms of damage reduction do not stack. Instead, the creature
gets the benefit of the best damage reduction in a given
situation.
    </p>
    <h5><a id="darkvision"></a>
DARKVISION
    </h5>
    <p className="initial">
Darkvision is the extraordinary ability to see with no light
source at all, out to a range specified for the creature.
Darkvision is black and white only (colors cannot be discerned).
It does not allow characters to see anything that they could not
see otherwise &mdash; invisible objects are still invisible, and illusions
are still visible as what they seem to be. Likewise, darkvision
subjects a creature to gaze attacks normally. The presence of
light does not spoil darkvision.
    </p>
    <h5><a id="death-attacks"></a>
DEATH ATTACKS
    </h5>
    <p className="initial">
In most cases, a death attack allows the victim a Fortitude save
to avoid the affect, but if the save fails, the character dies
instantly.
    </p>
    <ul>
      <li>
        <p>
Raise dead doesn&rsquo;t work on someone killed by a death attack.
        </p>
      </li>
      <li>
        <p>
Death attacks slay instantly. A victim cannot be made stable
and thereby kept alive.
        </p>
      </li>
      <li>
        <p>
In case it matters, a dead character, no matter how she died,
has &#8211;10 hit points.
        </p>
      </li>
      <li>
        <p>
The spell death ward protects a character against these
attacks.
        </p>
      </li>
    </ul>
    <h5><a id="disease"></a>
DISEASE
    </h5>
    <p className="initial">
When a character is injured by a contaminated attack touches an
item smeared with diseased matter, or consumes disease-tainted
food or drink, he must make an immediate Fortitude saving throw.
If he succeeds, the disease has no effect &mdash; his immune system fought
off the infection. If he fails, he takes damage after an
incubation period. Once per day afterward, he must make a
successful Fortitude saving throw to avoid repeated damage. Two
successful saving throws in a row indicate that he has fought off
the disease and recovers, taking no more damage.
    </p>
    <p>
These Fortitude saving throws can be rolled secretly so that the
player doesn&rsquo;t know whether the disease has taken hold.
    </p>
    <h6>
Disease Descriptions
    </h6>
    <p className="initial">
      <p>{`Diseases have various symptoms and are spread through a number of
vectors. The characteristics of several typical diseases are
summarized on Table: Diseases and defined below.`}</p>
    </p>
    <p>
      <i>Disease</i>: Diseases whose names are printed in italic in the
table are supernatural in nature. The others are extraordinary.
    </p>
    <p>
      <i>Infection</i>: The disease&rsquo;s method of delivery &mdash; ingested,
inhaled, via injury, or contact. Keep in mind that some injury
diseases may be transmitted by as small an injury as a flea bite
and that most inhaled diseases can also be ingested (and vice
versa).
    </p>
    <p>
      <i>DC</i>: The Difficulty Class for the Fortitude saving throws to
prevent infection (if the character has been infected), to prevent
each instance of repeated damage, and to recover from the disease.
    </p>
    <p>
      <i>Incubation Period</i>: The time before damage begins.
    </p>
    <p>
      <i>Damage</i>: The ability damage the character takes after
incubation and each day afterward.
    </p>
    <p>
      <b>Types of Diseases</b>: Typical diseases include the following:
    </p>
    <p><a id="blinding-sickness"></a>
      <i>Blinding Sickness</i>: Spread in tainted water.
    </p>
    <p><a id="cackle-fever"></a>
      <i>Cackle Fever</i>: Symptoms include high fever, disorientation,
and frequent bouts of hideous laughter. Also known as &ldquo;the
shrieks.&rdquo;
    </p>
    <p><a id="demon-fever"></a>
      <i>Demon Fever</i>: Night hags spread it. Can cause permanent
ability drain.
    </p>
    <p><a id="devil-chills"></a>
      <i>Devil Chills</i>: Barbazu and pit fiends spread it. It takes
three, not two, successful saves in a row to recover from devil
chills.
    </p>
    <p><a id="filth-fever"></a>
      <i>Filth Fever</i>: Dire rats and otyughs spread it. Those injured
while in filthy surroundings might also catch it.
    </p>
    <p><a id="mindfire"></a>
      <i>Mindfire</i>: Feels like your brain is burning. Causes stupor.
    </p>
    <p><a id="mummy-rot"></a>
      <i>Mummy Rot</i>: Spread by mummies. Successful saving throws do
not allow the character to recover (though they do prevent damage
normally).
    </p>
    <p><a id="red-ache"></a>
      <i>Red Ache</i>: Skin turns red, bloated, and warm to the touch.
    </p>
    <p><a id="the-shakes"></a>
      <i>The Shakes</i>: Causes involuntary twitches, tremors, and fits.
    </p>
    <p><a id="slimy-doom"></a>
      <i>Slimy Doom</i>: Victim turns into infectious goo from the
inside out. Can cause permanent ability drain.
    </p>
    <p><a id="table-diseases"></a>
      <b>Table: Diseases</b>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>
Disease
          </th>
          <th>
Infection DC
          </th>
          <th>
            <p>{`Incubation`}</p>
          </th>
          <th>
Damage
          </th>
        </tr>
        <tr className="odd-row">
          <td>
Blinding sickness
          </td>
          <td>
Ingested 16
          </td>
          <td>
1d3 days
          </td>
          <td>
1d4 Str<sup>1</sup>
          </td>
        </tr>
        <tr>
          <td>
Cackle fever
          </td>
          <td>
Inhaled 16
          </td>
          <td>
1 day
          </td>
          <td>
1d6 Wis
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Demon fever
          </td>
          <td>
Injury 18
          </td>
          <td>
1 day
          </td>
          <td>
1d6 Con<sup>2</sup>
          </td>
        </tr>
        <tr>
          <td>
Devil chills<sup>3</sup>
          </td>
          <td>
Injury 14
          </td>
          <td>
1d4 days
          </td>
          <td>
1d4 Str
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Filth fever
          </td>
          <td>
Injury 12
          </td>
          <td>
1d3 days
          </td>
          <td>
1d3 Dex, 1d3 Con
          </td>
        </tr>
        <tr>
          <td>
Mindfire
          </td>
          <td>
Inhaled 12
          </td>
          <td>
1 day
          </td>
          <td>
1d4 Int
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Mummy rot<sup>4</sup>
          </td>
          <td>
Contact 20
          </td>
          <td>
            <p>{`1 day`}</p>
          </td>
          <td>
1d6 Con
          </td>
        </tr>
        <tr>
          <td>
Red ache
          </td>
          <td>
Injury 15
          </td>
          <td>
1d3 days
          </td>
          <td>
1d6 Str
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Shakes
          </td>
          <td>
Contact 13
          </td>
          <td>
1 day
          </td>
          <td>
1d8 Dex
          </td>
        </tr>
        <tr>
          <td className="last-row">
Slimy doom
          </td>
          <td className="last-row">
Contact 14
          </td>
          <td className="last-row">
1 day
          </td>
          <td className="last-row">
1d4 Con<sup>2</sup>
          </td>
        </tr>
        <tr>
          <td colSpan="4">
1 <i>Each time the victim takes 2 or more damage from the
disease, he must make another Fortitude save or be
permanently blinded.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="4">
2 <i>When damaged, character must succeed on another saving
throw or 1 point of damage is permanent drain instead.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="4">
3 <i>The victim must make three successful Fortitude saving
throws in a row to recover from devil chills.</i>
          </td>
        </tr>
        <tr>
          <td colSpan="4">
4 <i>Successful saves do not allow the character to recover.
Only magical healing can save the character.</i>
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="healing-a-disease"></a>
Healing a Disease
    </h6>
    <p className="initial">
Use of the Heal skill can help a diseased character. Every time a
diseased character makes a saving throw against disease effects,
the healer makes a check. The diseased character can use the
healer&rsquo;s result in place of his saving throw if the Heal check
result is higher. The diseased character must be in the healer&rsquo;s
care and must have spent the previous 8 hours resting.
    </p>
    <p>
Characters recover points lost to ability score damage at a rate
of 1 per day per ability damaged, and this rule applies even while
a disease is in progress. That means that a character with a minor
disease might be able to withstand it without accumulating any
damage.
    </p>
    <h5><a id="energy-drain"></a><a id="energy-drain-and-negative-levels"></a>
ENERGY DRAIN AND NEGATIVE LEVELS
    </h5>
    <p className="initial">
      <p>{`Some horrible creatures, especially undead monsters, possess a
fearsome supernatural ability to drain levels from those they
strike in combat. The creature making an energy drain attack draws
a portion of its victim`}{`’`}{`s life force from her. Most energy drain
attacks require a successful melee attack roll `}{`—`}{` mere physical
contact is not enough. Each successful energy drain attack bestows
one or more negative levels on the opponent. A creature takes the
following penalties for each negative level it has gained.`}</p>
    </p>
    <dl>
      <dd>
        <p>
&#8211;1 on all skill checks and ability checks.
        </p>
      </dd>
    </dl>
    <dl>
      <dd>
        <p>
&#8211;1 on attack rolls and saving throws.
        </p>
      </dd>
    </dl>
    <dl>
      <dd>
        <p>
          <p>{`–`}{`5 hit points.`}</p>
        </p>
      </dd>
    </dl>
    <dl>
      <dd>
        <p>
&#8211;1 effective level (whenever the creatures level is used in a
die roll or calculation, reduce it by one for each negative
level).
        </p>
      </dd>
    </dl>
    <p>
If the victim casts spells, she loses access to one spell as if
she had cast her highest-level, currently available spell. (If she
has more than one spell at her highest level, she chooses which
she loses.) In addition, when she next prepares spells or regains
spell slots, she gets one less spell slot at her highest spell
level.
    </p>
    <p>
Negative levels remain for 24 hours or until removed with a spell,
such as restoration. After 24 hours, the afflicted creature must
attempt a Fortitude save (DC 10 + 1/2 attacker&rsquo;s HD + attacker&rsquo;s
Cha modifier). (The DC is provided in the attacker&rsquo;s description.)
If the saving throw succeeds, the negative level goes away with no
harm to the creature. The afflicted creature makes a separate
saving throw for each negative level it has gained. If the save
fails, the negative level goes away, but the creature&rsquo;s level is
also reduced by one.
    </p>
    <p>
A character with negative levels at least equal to her current
level, or drained below 1st level, is instantly slain. Depending
on the creature that killed her, she may rise the next night as a
monster of that kind. If not, she rises as a wight. A creature
gains 5 temporary hit points for each negative level it bestows
(though not if the negative level is caused by a spell or similar
effect).
    </p>
    <h5><a id="etherealness"></a>
ETHEREALNESS
    </h5>
    <p className="initial">
Phase spiders and certain other creatures can exist on the
Ethereal Plane. While on the Ethereal Plane, a creature is called
ethereal. Unlike incorporeal creatures, ethereal creatures are not
present on the Material Plane.
    </p>
    <p>
Ethereal creatures are invisible, inaudible, insubstantial, and
scentless to creatures on the Material Plane. Even most magical
attacks have no effect on them. See invisibility and true seeing
reveal ethereal creatures.
    </p>
    <p>
      <p>{`An ethereal creature can see and hear into the Material Plane in a
60-foot radius, though material objects still block sight and
sound. (An ethereal creature can`}{`’`}{`t see through a material wall,
for instance.) An ethereal creature inside an object on the
Material Plane cannot see. Things on the Material Plane, however,
look gray, indistinct, and ghostly. An ethereal creature can`}{`’`}{`t
affect the Material Plane, not even magically. An ethereal
creature, however, interacts with other ethereal creatures and
objects the way material creatures interact with material
creatures and objects.`}</p>
    </p>
    <p>
Even if a creature on the Material Plane can see an ethereal
creature the ethereal creature is on another plane. Only force
effects can affect the ethereal creatures. If, on the other hand,
both creatures are ethereal, they can affect each other normally.
    </p>
    <p>
A force effect originating on the Material Plane extends onto the
Ethereal Plane, so that a wall of force blocks an ethereal
creature, and a magic missile can strike one (provided the
spellcaster can see the ethereal target). Gaze effects and
abjurations also extend from the Material Plane to the Ethereal
Plane. None of these effects extend from the Ethereal Plane to the
Material Plane.
    </p>
    <p>
Ethereal creatures move in any direction (including up or down) at
will. They do not need to walk on the ground, and material objects
don&rsquo;t block them (though they can&rsquo;t see while their eyes are
within solid material).
    </p>
    <p>
Ghosts have a power called manifestation that allows them to
appear on the Material Plane as incorporeal creatures. Still, they
are on the Ethereal Plane, and another ethereal creature can
interact normally with a manifesting ghost. Ethereal creatures
pass through and operate in water as easily as air. Ethereal
creatures do not fall or take falling damage.
    </p>
    <h5><a id="evasion"></a><a id="evasion-and-improved-evasion"></a>
EVASION AND IMPROVED EVASION
    </h5>
    <p className="initial">
These extraordinary abilities allow the target of an area attack
to leap or twist out of the way. Rogues and monks have evasion and
improved evasion as class features, but certain other creatures
have these abilities, too.
    </p>
    <p>
      <p>{`If subjected to an attack that allows a Reflex save for half
damage, a character with evasion takes no damage on a successful
save.`}</p>
    </p>
    <p>
As with a Reflex save for any creature, a character must have room
to move in order to evade. A bound character or one squeezing
through an area cannot use evasion.
    </p>
    <p>
As with a Reflex save for any creature, evasion is a reflexive
ability. The character need not know that the attack is coming to
use evasion.
    </p>
    <p>
Rogues and monks cannot use evasion in medium or heavy armor. Some
creatures with the evasion ability as an innate quality do not
have this limitation.
    </p>
    <p>
Improved evasion is like evasion, except that even on a failed
saving throw the character takes only half damage.
    </p>
    <h5><a id="fast-healing"></a>
FAST HEALING
    </h5>
    <p className="initial">
A creature with fast healing has the extraordinary ability to
regain hit points at an exceptional rate. Except for what is noted
here, fast healing is like natural healing.
    </p>
    <p>
      <p>{`At the beginning of each of the creature`}{`’`}{`s turns, it heals a
certain number of hit points (defined in its description).`}</p>
    </p>
    <p>
Unlike regeneration, fast healing does not allow a creature to
regrow or reattach lost body parts.
    </p>
    <p>
A creature that has taken both nonlethal and lethal damage heals
the nonlethal damage first.
    </p>
    <p>
Fast healing does not restore hit points lost from starvation,
thirst, or suffocation.
    </p>
    <p>
Fast healing does not increase the number of hit points regained
when a creature polymorphs.
    </p>
    <h5><a id="fear"></a>
FEAR
    </h5>
    <p className="initial">
Spells, magic items, and certain monsters can affect characters
with fear. In most cases, the character makes a Will saving throw
to resist this effect, and a failed roll means that the character
is shaken, frightened, or panicked.
    </p>
    <p><a id="fear-shaken"></a>
      <b>Shaken</b>: Characters who are shaken take a &#8211;2 penalty on
attack rolls, saving throws, skill checks, and ability checks.
    </p>
    <p><a id="fear-frightened"></a>
      <b>Frightened</b>: Characters who are frightened are shaken, and
in addition they flee from the source of their fear as quickly as
they can. They can choose the path of their flight. Other than
that stipulation, once they are out of sight (or hearing) of the
source of their fear, they can act as they want. However, if the
duration of their fear continues, characters can be forced to flee
once more if the source of their fear presents itself again.
Characters unable to flee can fight (though they are still
shaken).
    </p>
    <p><a id="fear-panicked"></a>
      <b>Panicked</b>: Characters who are panicked are shaken, and they
run away from the source of their fear as quickly as they can.
Other than running away from the source, their path is random.
They flee from all other dangers that confront them rather than
facing those dangers. Panicked characters cower if they are
prevented from fleeing.
    </p>
    <p><a id="fear-becoming-even-more-frightful"></a>
      <b>Becoming Even More Fearful</b>: Fear effects are cumulative. A
shaken character who is made shaken again becomes frightened, and
a shaken character who is made frightened becomes panicked
instead. A frightened character who is made shaken or frightened
becomes panicked instead.
    </p>
    <h5><a id="fire-immunity"></a>
FIRE IMMUNITY
    </h5>
    <p className="initial">
A creature with fire immunity never takes fire damage. It has
vulnerability to cold, which means it takes half again as much
(+50%) damage as normal from cold, regardless of whether a saving
throw is allowed, or if the save is a success or failure.
    </p>
    <h5><a id="gaseous-form"></a>
GASEOUS FORM
    </h5>
    <p className="initial">
Some creatures have the supernatural or spell-like ability to take
the form of a cloud of vapor or gas.
    </p>
    <p>
Creatures in gaseous form can&rsquo;t run but can fly. A gaseous
creature can move about and do the things that a cloud of gas can
conceivably do, such as flow through the crack under a door. It
can&rsquo;t, however, pass through solid matter. Gaseous creatures can&rsquo;t
attack physically or cast spells with verbal, somatic, material,
or focus components. They lose their supernatural abilities
(except for the supernatural ability to assume gaseous form, of
course).
    </p>
    <p>
Creatures in gaseous form have damage reduction 10/magic. Spells,
spell-like abilities, and supernatural abilities affect them
normally. Creatures in gaseous form lose all benefit of material
armor (including natural armor), though size, Dexterity,
deflection bonuses, and armor bonuses from force armor still
apply.
    </p>
    <p>
      <p>{`Gaseous creatures do not need to breathe and are immune to attacks
involving breathing (troglodyte stench, poison gas, and the like).`}</p>
    </p>
    <p>
Gaseous creatures can&rsquo;t enter water or other liquid. They are not
ethereal or incorporeal. They are affected by winds or other forms
of moving air to the extent that the wind pushes them in the
direction the wind is moving. However, even the strongest wind
can&rsquo;t disperse or damage a creature in gaseous form.
    </p>
    <p>
Discerning a creature in gaseous form from natural mist requires a
DC 15 Spot check. Creatures in gaseous form attempting to hide in
an area with mist, smoke, or other gas gain a +20 bonus.
    </p>
    <h5><a id="gaze-attacks"></a>
GAZE ATTACKS
    </h5>
    <p className="initial">
While the medusa&rsquo;s gaze is well known, gaze attacks can also
charm, curse, or even kill. Gaze attacks not produced by a spell
are supernatural.
    </p>
    <p>
Each character within range of a gaze attack must attempt a saving
throw (which can be a Fortitude or Will save) each round at the
beginning of his turn.
    </p>
    <p>
An opponent can avert his eyes from the creature&rsquo;s face, looking
at the creature&rsquo;s body, watching its shadow, or tracking the
creature in a reflective surface. Each round, the opponent has a
50% chance of not having to make a saving throw. The creature with
the gaze attack gains concealment relative to the opponent. An
opponent can shut his eyes, turn his back on the creature, or wear
a blindfold. In these cases, the opponent does not need to make a
saving throw. The creature with the gaze attack gains total
concealment relative to the opponent.
    </p>
    <p>
      <p>{`A creature with a gaze attack can actively attempt to use its gaze
as an attack action. The creature simply chooses a target within
range, and that opponent must attempt a saving throw. If the
target has chosen to defend against the gaze as discussed above,
the opponent gets a chance to avoid the saving throw (either 50%
chance for averting eyes or 100% chance for shutting eyes). It is
possible for an opponent to save against a creature`}{`’`}{`s gaze twice
during the same round, once before its own action and once during
the creature`}{`’`}{`s action.`}</p>
    </p>
    <p>
Looking at the creature&rsquo;s image (such as in a mirror or as part of
an illusion) does not subject the viewer to a gaze attack.
    </p>
    <p>
A creature is immune to its own gaze attack.
    </p>
    <p>
If visibility is limited (by dim lighting, a fog, or the like) so
that it results in concealment, there is a percentage chance equal
to the normal miss chance for that degree of concealment that a
character won&rsquo;t need to make a saving throw in a given round. This
chance is not cumulative with the chance for averting your eyes,
but is rolled separately.
    </p>
    <p>
Invisible creatures cannot use gaze attacks.
    </p>
    <p>
Characters using darkvision in complete darkness are affected by a
gaze attack normally.
    </p>
    <p>
Unless specified otherwise, a creature with a gaze attack can
control its gaze attack and &ldquo;turn it off &rdquo; when so desired.
    </p>
    <h5><a id="incorporeality"></a>
INCORPOREALITY
    </h5>
    <p className="initial">
Spectres, wraiths, and a few other creatures lack physical bodies.
Such creatures are insubstantial and cant be touched by
nonmagical matter or energy. Likewise, they cannot manipulate
objects or exert physical force on objects. However, incorporeal
beings have a tangible presence that sometimes seems like a
physical attack against a corporeal creature.
    </p>
    <p>
Incorporeal creatures are present on the same plane as the
characters, and characters have some chance to affect them.
    </p>
    <p>
Incorporeal creatures can be harmed only by other incorporeal
creatures, by magic weapons, or by spells, spell-like effects, or
supernatural effects. They are immune to all nonmagical attack
forms. They are not burned by normal fires, affected by natural
cold, or harmed by mundane acids.
    </p>
    <p>
Even when struck by magic or magic weapons, an incorporeal
creature has a 50% chance to ignore any damage from a corporeal
source &mdash; except for a force effect or damage dealt by a ghost touch
weapon.
    </p>
    <p>
Incorporeal creatures are immune to critical hits, extra damage
from being favored enemies, and from sneak attacks. They move in
any direction (including up or down) at will. They do not need to
walk on the ground. They can pass through solid objects at will,
although they cannot see when their eyes are within solid matter.
    </p>
    <p>
Incorporeal creatures hiding inside solid objects get a +2
circumstance bonus on Listen checks, because solid objects carry
sound well. Pinpointing an opponent from inside a solid object
uses the same rules as pinpointing invisible opponents (see
Invisibility, below).
    </p>
    <p>
      <p>{`Incorporeal creatures are inaudible unless they decide to make
noise.`}</p>
    </p>
    <p>
The physical attacks of incorporeal creatures ignore material
armor, even magic armor, unless it is made of force (such as mage
armor or bracers of armor) or has the ghost touch ability.
    </p>
    <p>
Incorporeal creatures pass through and operate in water as easily
as they do in air.
    </p>
    <p>
Incorporeal creatures cannot fall or take falling damage.
    </p>
    <p>
Corporeal creatures cannot trip or grapple incorporeal creatures.
    </p>
    <p>
Incorporeal creatures have no weight and do not set off traps that
are triggered by weight.
    </p>
    <p>
Incorporeal creatures do not leave footprints, have no scent, and
make no noise unless they manifest, and even then they only make
noise intentionally.
    </p>
    <h5><a id="invisibility"></a>
INVISIBILITY
    </h5>
    <p className="initial">
The ability to move about unseen is not foolproof. While they
can't be seen, invisible creatures can be heard, smelled, or felt.
    </p>
    <p>
Invisibility makes a creature undetectable by vision, including
darkvision.
    </p>
    <p>
Invisibility does not, by itself, make a creature immune to
critical hits, but it does make the creature immune to extra
damage from being a ranger's favored enemy and from sneak attacks.
    </p>
    <p>
A creature can generally notice the presence of an active
invisible creature within 30 feet with a DC 20 Spot check. The
observer gains a hunch that &ldquo;something&rsquo;s there&rdquo; but can&rsquo;t see it
or target it accurately with an attack. A creature who is holding
still is very hard to notice (DC 30). An inanimate object, an
unliving creature holding still, or a completely immobile creature
is even harder to spot (DC 40). It's practically impossible (+20
DC) to pinpoint an invisible creature's location with a Spot
check, and even if a character succeeds on such a check, the
invisible creature still benefits from total concealment (50% miss
chance).
    </p>
    <p>
A creature can use hearing to find an invisible creature. A
character can make a Listen check for this purpose as a free
action each round. A Listen check result at least equal to the
invisible creature's Move Silently check result reveals its
presence. (A creature with no ranks in Move Silently makes a Move
Silently check as a Dexterity check to which an armor check
penalty applies.) A successful check lets a character hear an
invisible creature &ldquo;over there somewhere.&rdquo; It's practically
impossible to pinpoint the location of an invisible creature. A
Listen check that beats the DC by 20 pinpoints the invisible
creature's location.
    </p>
    <p><a id="table-listen-check-dcs-to-detect-invisible-creatures"></a>
      <b>Table: Listen Check DCs to Detect Invisible Creatures</b>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "400px"
    }}>
      <tbody>
        <tr>
          <th>
Invisible Creature Is . . .
          </th>
          <th>
DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>
In combat or speaking
          </td>
          <td>
0
          </td>
        </tr>
        <tr>
          <td>
            <p>{`Moving at half speed`}</p>
          </td>
          <td>
Move Silently check result
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Moving at full speed
          </td>
          <td>
Move Silently check result &#8211;4
          </td>
        </tr>
        <tr>
          <td>
Running or charging
          </td>
          <td>
Move Silently check result &#8211;20
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Some distance away
          </td>
          <td>
+1 per 10 feet
          </td>
        </tr>
        <tr>
          <td>
Behind an obstacle (door)
          </td>
          <td>
+5
          </td>
        </tr>
        <tr className="odd-row">
          <td>
Behind an obstacle (stone wall)
          </td>
          <td>
+15
          </td>
        </tr>
      </tbody>
    </table>
    <p>
A creature can grope about to find an invisible creature. A
character can make a touch attack with his hands or a weapon into
two adjacent 5-foot squares using a standard action. If an
invisible target is in the designated area, there is a 50% miss
chance on the touch attack. If successful, the groping character
deals no damage but has successfully pinpointed the invisible
creature&rsquo;s current location. (If the invisible creature moves, its
location, obviously, is once again unknown.)
    </p>
    <p>
      <p>{`If an invisible creature strikes a character, the character struck
still knows the location of the creature that struck him (until,
of course, the invisible creature moves). The only exception is if
the invisible creature has a reach greater than 5 feet. In this
case, the struck character knows the general location of the
creature but has not pinpointed the exact location.`}</p>
    </p>
    <p>
If a character tries to attack an invisible creature whose
location he has pinpointed, he attacks normally, but the invisible
creature still benefits from full concealment (and thus a 50% miss
chance). A particularly large and slow creature might get a
smaller miss chance.
    </p>
    <p>
If a character tries to attack an invisible creature whose
location he has not pinpointed, have the player choose the space
where the character will direct the attack. If the invisible
creature is there, conduct the attack normally. If the enemy&rsquo;s not
there, roll the miss chance as if it were there, don&rsquo;t let the
player see the result, and tell him that the character has missed.
That way the player doesn&rsquo;t know whether the attack missed because
the enemy&rsquo;s not there or because you successfully rolled the miss
chance.
    </p>
    <p>
If an invisible character picks up a visible object, the object
remains visible. One could coat an invisible object with flour to
at least keep track of its position (until the flour fell off or
blew away). An invisible creature can pick up a small visible item
and hide it on his person (tucked in a pocket or behind a cloak)
and render it effectively invisible.
    </p>
    <p>
Invisible creatures leave tracks. They can be tracked normally.
Footprints in sand, mud, or other soft surfaces can give enemies
clues to an invisible creature&rsquo;s location.
    </p>
    <p>
An invisible creature in the water displaces water, revealing its
location. The invisible creature, however, is still hard to see
and benefits from concealment.
    </p>
    <p>
A creature with the scent ability can detect an invisible creature
as it would a visible one.
    </p>
    <p>
      <p>{`A creature with the Blind-Fight feat has a better chance to hit an
invisible creature. Roll the miss chance twice, and he misses only
if both rolls indicate a miss. (Alternatively, make one 25% miss
chance roll rather than two 50% miss chance rolls.)`}</p>
    </p>
    <p>
A creature with blindsight can attack (and otherwise interact
with) creatures regardless of invisibility.
    </p>
    <p>
An invisible burning torch still gives off light, as does an
invisible object with a light spell (or similar spell) cast upon
it.
    </p>
    <p>
Ethereal creatures are invisible. Since ethereal creatures are not
materially present, Spot checks, Listen checks, Scent,
Blind-Fight, and blindsight don&rsquo;t help locate them. Incorporeal
creatures are often invisible. Scent, Blind-Fight, and blindsight
don&rsquo;t help creatures find or attack invisible, incorporeal
creatures, but Spot checks and possibly Listen checks can help.
    </p>
    <p>
Invisible creatures cannot use gaze attacks.
    </p>
    <p>
Invisibility does not thwart detect spells.
    </p>
    <p>
Since some creatures can detect or even see invisible creatures,
it is helpful to be able to hide even when invisible.
    </p>
    <h5><a id="level-loss"></a>
LEVEL LOSS
    </h5>
    <p className="initial">
A character who loses a level instantly loses one Hit Die. The
character&rsquo;s base attack bonus, base saving throw bonuses, and
special class abilities are now reduced to the new, lower level.
Likewise, the character loses any ability score gain, skill ranks,
and any feat associated with the level (if applicable). If the
exact ability score or skill ranks increased from a level now lost
is unknown (or the player has forgotten), lose 1 point from the
highest ability score or ranks from the highest-ranked skills. If
a familiar or companion creature has abilities tied to a character
who has lost a level, the creature&rsquo;s abilities are adjusted to fit
the character&rsquo;s new level.
    </p>
    <p>
The victim&rsquo;s experience point total is immediately set to the
midpoint of the previous level.
    </p>
    <h5><a id="low-light-vision"></a>
LOW-LIGHT VISION
    </h5>
    <p className="initial">
Characters with low-light vision have eyes that are so sensitive
to light that they can see twice as far as normal in dim light.
Low-light vision is color vision. A spellcaster with low-light
vision can read a scroll as long as even the tiniest candle flame
is next to her as a source of light.
    </p>
    <p>
Characters with low-light vision can see outdoors on a moonlit
night as well as they can during the day.
    </p>
    <h5><a id="paralysis"></a>
PARALYSIS
    </h5>
    <p className="initial">
      <p>{`Some monsters and spells have the supernatural or spell-like
ability to paralyze their victims, immobilizing them through
magical means. (Paralysis from toxins is discussed in the Poison
section below.)`}</p>
    </p>
    <p>
A paralyzed character cannot move, speak, or take any physical
action. He is rooted to the spot, frozen and helpless. Not even
friends can move his limbs. He may take purely mental actions,
such as casting a spell with no components.
    </p>
    <p>
A winged creature flying in the air at the time that it becomes
paralyzed cannot flap its wings and falls. A swimmer can&rsquo;t swim
and may drown.
    </p>
    <h5><a id="poison"></a>
POISON
    </h5>
    <p className="initial">
When a character takes damage from an attack with a poisoned
weapon, touches an item smeared with contact poison, consumes
poisoned food or drink, or is otherwise poisoned, he must make a
Fortitude saving throw. If he fails, he takes the poison&rsquo;s initial
damage (usually ability damage). Even if he succeeds, he typically
faces more damage 1 minute later, which he can also avoid with a
successful Fortitude saving throw.
    </p>
    <p>
One dose of poison smeared on a weapon or some other object
affects just a single target. A poisoned weapon or object retains
its venom until the weapon scores a hit or the object is touched
(unless the poison is wiped off before a target comes in contact
with it). Any poison smeared on an object or exposed to the
elements in any way remains potent until it is touched or used.
    </p>
    <p>
Although supernatural and spell-like poisons are possible,
poisonous effects are almost always extraordinary.
    </p>
    <p>
      <p>{`Poisons can be divided into four basic types according to the
method by which their effect is delivered, as follows.`}</p>
    </p>
    <p><a id="contact-poison"></a>
      <b>Contact</b>: Merely touching this type of poison necessitates a
saving throw. It can be actively delivered via a weapon or a touch
attack. Even if a creature has sufficient damage reduction to
avoid taking any damage from the attack, the poison can still
affect it. A chest or other object can be smeared with contact
poison as part of a trap.
    </p>
    <p><a id="ingested-poison"></a>
      <b>Ingested</b>: Ingested poisons are virtually impossible to
utilize in a combat situation. A poisoner could administer a
potion to an unconscious creature or attempt to dupe someone into
drinking or eating something poisoned. Assassins and other
characters tend to use ingested poisons outside of combat.
    </p>
    <p><a id="inhaled-poison"></a>
      <b>Inhaled</b>: Inhaled poisons are usually contained in fragile
vials or eggshells. They can be thrown as a ranged attack with a
range increment of 10 feet. When it strikes a hard surface (or is
struck hard), the container releases its poison. One dose spreads
to fill the volume of a 10-foot cube. Each creature within the
area must make a saving throw. (Holding one&rsquo;s breath is
ineffective against inhaled poisons; they affect the nasal
membranes, tear ducts, and other parts of the body.)
    </p>
    <p><a id="injury-poison"></a>
      <b>Injury</b>: This poison must be delivered through a wound. If a
creature has sufficient damage reduction to avoid taking any
damage from the attack, the poison does not affect it. Traps that
cause damage from weapons, needles, and the like sometimes contain
injury poisons.
    </p>
    <p>
The characteristics of poisons are summarized on Table: Poisons.
Terms on the table are defined below.
    </p>
    <p><a id="poison-type"></a>
      <i>Type</i>: The poison&rsquo;s method of delivery (contact, ingested,
inhaled, or via an injury) and the Fortitude save DC to avoid the
poison&rsquo;s damage.
    </p>
    <p><a id="poison-initial-damage"></a>
      <i>Initial Damage</i>: The damage the character takes immediately
upon failing his saving throw against this poison. Ability damage
is temporary unless marked with an asterisk (*), in which case the
loss is a permanent drain. Paralysis lasts for 2d6 minutes.
    </p>
    <p><a id="poison-secondary-damage"></a>
      <i>Secondary Damage</i>: The amount of damage the character takes
1 minute after exposure as a result of the poisoning, if he fails
a second saving throw. Unconsciousness lasts for 1d3 hours.
Ability damage marked with an asterisk is permanent drain instead
of temporary damage.
    </p>
    <p><a id="poison-price"></a>
      <i>Price</i>: The cost of one dose (one vial) of the poison. It is
not possible to use or apply poison in any quantity smaller than
one dose. The purchase and possession of poison is always illegal,
and even in big cities it can be obtained only from specialized,
less than reputable sources.
    </p>
    <h6><a id="perils-of-using-poison"></a>
Perils of Using Poison
    </h6>
    <p className="initial">
A character has a 5% chance of exposing himself to a poison
whenever he applies it to a weapon or otherwise readies it for
use. Additionally, a character who rolls a natural 1 on an attack
roll with a poisoned weapon must make a DC 15 Reflex save or
accidentally poison himself with the weapon.
    </p>
    <h6><a id="poison-immunities"></a>
Poison Immunities
    </h6>
    <p className="initial">
Creatures with natural poison attacks are immune to their own
poison. Nonliving creatures (constructs and undead) and creatures
without metabolisms (such as elementals) are always immune to
poison. Oozes, plants, and certain kinds of outsiders are also
immune to poison, although conceivably special poisons could be
concocted specifically to harm them.
    </p>
    <p><a id="table-poisons"></a>
      <b>Table: Poisons</b>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th>
Poison
          </th>
          <th>
Type
          </th>
          <th>
Initial Damage
          </th>
          <th>
            <p>{`Secondary Damage`}</p>
          </th>
          <th>
Price
          </th>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="nitharit"></a>Nitharit
          </td>
          <td>
Contact DC 13
          </td>
          <td>
0
          </td>
          <td>
3d6 Con
          </td>
          <td>
            <p>{`650 gp`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <a id="sassone-leaf-residue"></a>Sassone leaf residue
          </td>
          <td>
Contact DC 16
          </td>
          <td>
2d12 hp
          </td>
          <td>
1d6 Con
          </td>
          <td>
300 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="malyss-root-paste"></a>Malyss root paste
          </td>
          <td>
Contact DC 16
          </td>
          <td>
1 Dex
          </td>
          <td>
2d4 Dex
          </td>
          <td>
500 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="terinav-root"></a>Terinav root
          </td>
          <td>
Contact DC 16
          </td>
          <td>
1d6 Dex
          </td>
          <td>
2d6 Dex
          </td>
          <td>
            <p>{`750 gp`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="black-lotus-extract"></a>Black lotus extract
          </td>
          <td>
Contact DC 20
          </td>
          <td>
3d6 Con
          </td>
          <td>
3d6 Con
          </td>
          <td>
4,500 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="dragon-bile"></a>Dragon bile
          </td>
          <td>
Contact DC 26
          </td>
          <td>
3d6 Str
          </td>
          <td>
0
          </td>
          <td>
1,500 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="striped-toadstool"></a>Striped toadstool
          </td>
          <td>
Ingested DC 11
          </td>
          <td>
1 Wis
          </td>
          <td>
2d6 Wis + 1d4 Int
          </td>
          <td>
            <p>{`180 gp`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <a id="arsenic"></a>Arsenic
          </td>
          <td>
Ingested DC 13
          </td>
          <td>
1 Con
          </td>
          <td>
1d8 Con
          </td>
          <td>
120 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="id-moss"></a>Id moss
          </td>
          <td>
Ingested DC 14
          </td>
          <td>
1d4 Int
          </td>
          <td>
2d6 Int
          </td>
          <td>
125 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="oil-of-taggit"></a>Oil of taggit
          </td>
          <td>
Ingested DC 15
          </td>
          <td>
0
          </td>
          <td>
Unconsciousness
          </td>
          <td>
            <p>{`90 gp`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="lich-dust"></a>Lich dust
          </td>
          <td>
Ingested DC 17
          </td>
          <td>
2d6 Str
          </td>
          <td>
1d6 Str
          </td>
          <td>
250 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="dark-reaver-powder"></a>Dark reaver powder
          </td>
          <td>
Ingested DC 18
          </td>
          <td>
2d6 Con
          </td>
          <td>
1d6 Con + 1d6 Str
          </td>
          <td>
300 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="ungol-dust"></a>Ungol dust
          </td>
          <td>
Inhaled DC 15
          </td>
          <td>
1 Cha
          </td>
          <td>
1d6 Cha + 1 Cha<sup>*</sup>
          </td>
          <td>
1,000 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="insanity-mist"></a>Insanity mist
          </td>
          <td>
Inhaled DC 15
          </td>
          <td>
1d4 Wis
          </td>
          <td>
2d6 Wis
          </td>
          <td>
1,500 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="burnt-othur-fumes"></a>Burnt othur fumes
          </td>
          <td>
Inhaled DC 18
          </td>
          <td>
1 Con<sup>*</sup>
          </td>
          <td>
3d6 Con
          </td>
          <td>
2,100 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="black-adders-venom"></a>Black adder venom
          </td>
          <td>
Injury DC 11
          </td>
          <td>
1d6 Con
          </td>
          <td>
1d6 Con
          </td>
          <td>
120 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="small-centipede-poison"></a>Small centipede poison
          </td>
          <td>
Injury DC 11
          </td>
          <td>
1d2 Dex
          </td>
          <td>
1d2 Dex
          </td>
          <td>
90 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="bloodroot"></a>Bloodroot
          </td>
          <td>
Injury DC 12
          </td>
          <td>
0
          </td>
          <td>
1d4 Con + 1d3 Wis
          </td>
          <td>
100 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="drow-poison"></a>Drow poison
          </td>
          <td>
Injury DC 13
          </td>
          <td>
Unconsciousness
          </td>
          <td>
Unconsciousness for 2d4 hours
          </td>
          <td>
            <p>{`75gp`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <a id="greenblood-oil"></a>Greenblood oil
          </td>
          <td>
Injury DC 13
          </td>
          <td>
1 Con
          </td>
          <td>
1d2 Con
          </td>
          <td>
100 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="blue-whinnis"></a>Blue whinnis
          </td>
          <td>
Injury DC 14
          </td>
          <td>
1 Con
          </td>
          <td>
Unconsciousness
          </td>
          <td>
120 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="medium-spider-venom"></a>Medium spider venom
          </td>
          <td>
Injury DC 14
          </td>
          <td>
1d4 Str
          </td>
          <td>
1d4 Str
          </td>
          <td>
            <p>{`150 gp`}</p>
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="shadow-essence"></a>Shadow essence
          </td>
          <td>
Injury DC 17
          </td>
          <td>
1 Str<sup>*</sup>
          </td>
          <td>
2d6 Str
          </td>
          <td>
250 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="wyvern-poison"></a>Wyvern poison
          </td>
          <td>
Injury DC 17
          </td>
          <td>
2d6 Con
          </td>
          <td>
2d6 Con
          </td>
          <td>
3,000 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="large-scorpion-venom"></a>Large scorpion venom
          </td>
          <td>
Injury DC 18
          </td>
          <td>
1d6 Str
          </td>
          <td>
1d6 Str
          </td>
          <td>
            <p>{`200 gp`}</p>
          </td>
        </tr>
        <tr>
          <td>
            <a id="giant-wasp-poison"></a>Giant wasp poison
          </td>
          <td>
Injury DC 18
          </td>
          <td>
1d6 Dex
          </td>
          <td>
1d6 Dex
          </td>
          <td>
210 gp
          </td>
        </tr>
        <tr className="odd-row">
          <td>
            <a id="deathblade"></a>Deathblade
          </td>
          <td>
Injury DC 20
          </td>
          <td>
1d6 Con
          </td>
          <td>
2d6 Con
          </td>
          <td>
1,800 gp
          </td>
        </tr>
        <tr>
          <td>
            <a id="purple-worm-poison"></a>Purple worm poison
          </td>
          <td>
Injury DC 24
          </td>
          <td>
1d6 Str
          </td>
          <td>
2d6 Str
          </td>
          <td>
            <p>{`700 gp`}</p>
          </td>
        </tr>
        <tr>
          <td colSpan="5">
* <i>Permanent drain, not temporary damage.</i>
          </td>
        </tr>
      </tbody>
    </table>
    <h5><a id="polymorph"></a>
POLYMORPH
    </h5>
    <p className="initial">
Magic can cause creatures and characters to change their
shapes &mdash; sometimes against their will, but usually to gain an
advantage. Polymorphed creatures retain their own minds but have
new physical forms.
    </p>
    <p>
The polymorph spell defines the general polymorph effect.
    </p>
    <p>
Unless stated otherwise, creatures can polymorph into forms of the
same type or into an aberration, animal, dragon, fey, giant,
humanoid, magical beast, monstrous humanoid, ooze, plant, or
vermin form. Most spells and abilities that grant the ability to
polymorph place a cap on the Hit Dice of the form taken.
    </p>
    <p>
Polymorphed creatures gain the Strength, Dexterity, and
Constitution of their new forms, as well as size, extraordinary
special attacks, movement capabilities (to a maximum of 120 feet
for flying and 60 for nonflying movement), natural armor bonus,
natural weapons, racial skill bonuses, and other gross physical
qualities such as appearance and number of limbs. They retain
their original class and level, Intelligence, Wisdom, Charisma,
hit points, base attack bonus, base save bonuses, and alignment.
    </p>
    <p>
Creatures who polymorph keep their worn or held equipment if the
new form is capable of wearing or holding it.
    </p>
    <p>
      <p>{`Otherwise, it melds with the new form and ceases to function for
the duration of the polymorph.`}</p>
    </p>
    <h5><a id="psionics"></a>
PSIONICS
    </h5>
    <p className="initial">
Telepathy, mental combat and psychic powers &mdash; psionics is a catchall
word that describes special mental abilities possessed by various
creatures. These are spell-like abilities that a creature
generates from the power of its mind alone &mdash; no other outside
magical force or ritual is needed. Each psionic creature&rsquo;s
description contains details on its psionic abilities.
    </p>
    <p>
Psionic attacks almost always allow Will saving throws to resist
them. However, not all psionic attacks are mental attacks. Some
psionic abilities allow the psionic creature to reshape its own
body, heal its wounds, or teleport great distances. Some psionic
creatures can see into the future, the past, and the present (in
far-off locales) as well as read the minds of others.
    </p>
    <h5><a id="rays"></a>
RAYS
    </h5>
    <p className="initial">
All ray attacks require the attacker to make a successful ranged
touch attack against the target. Rays have varying ranges, which
are simple maximums. A ray&rsquo;s attack roll never takes a range
penalty. Even if a ray hits, it usually allows the target to make
a saving throw (Fortitude or Will). Rays never allow a Reflex
saving throw, but if a character&rsquo;s Dexterity bonus to AC is high,
it might be hard to hit her with the ray in the first place.
    </p>
    <h5><a id="regeneration"></a>
REGENERATION
    </h5>
    <p className="initial">
      <p>{`Creatures with this extraordinary ability recover from wounds
quickly and can even regrow or reattach severed body parts. Damage
dealt to the creature is treated as nonlethal damage, and the
creature automatically cures itself of nonlethal damage at a fixed
rate.`}</p>
    </p>
    <p>
Certain attack forms, typically fire and acid, deal damage to the
creature normally; that sort of damage doesn&rsquo;t convert to
nonlethal damage and so doesn&rsquo;t go away. The creature&rsquo;s
description includes the details.
    </p>
    <p>
Creatures with regeneration can regrow lost portions of their
bodies and can reattach severed limbs or body parts. Severed parts
die if they are not reattached.
    </p>
    <p>
Regeneration does not restore hit points lost from starvation,
thirst, or suffocation.
    </p>
    <p>
Attack forms that don&rsquo;t deal hit point damage ignore regeneration.
    </p>
    <p>
An attack that can cause instant death only threatens the creature
with death if it is delivered by weapons that deal it lethal
damage.
    </p>
    <h5><a id="resistance-to-energy"></a>
RESISTANCE TO ENERGY
    </h5>
    <p className="initial">
      <p>{`A creature with resistance to energy has the ability (usually
extraordinary) to ignore some damage of a certain type each round,
but it does not have total immunity.`}</p>
    </p>
    <p>
Each resistance ability is defined by what energy type it resists
and how many points of damage are resisted. It doesn&rsquo;t matter
whether the damage has a mundane or magical source.
    </p>
    <p>
When resistance completely negates the damage from an energy
attack, the attack does not disrupt a spell. This resistance does
not stack with the resistance that a spell might provide.
    </p>
    <h5><a id="scent"></a>
SCENT
    </h5>
    <p className="initial">
This extraordinary ability lets a creature detect approaching
enemies, sniff out hidden foes, and track by sense of smell.
    </p>
    <p>
A creature with the scent ability can detect opponents by sense of
smell, generally within 30 feet. If the opponent is upwind, the
range is 60 feet. If it is downwind, the range is 15 feet. Strong
scents, such as smoke or rotting garbage, can be detected at twice
the ranges noted above. Overpowering scents, such as skunk musk or
troglodyte stench, can be detected at three times these ranges.
    </p>
    <p>
The creature detects another creature&rsquo;s presence but not its
specific location. Noting the direction of the scent is a move
action. If it moves within 5 feet of the scent&rsquo;s source, the
creature can pinpoint that source.
    </p>
    <p>
      <p>{`A creature with the Track feat and the scent ability can follow
tracks by smell, making a Wisdom check to find or follow a track.
The typical DC for a fresh trail is 10. The DC increases or
decreases depending on how strong the quarry`}{`’`}{`s odor is, the number
of creatures, and the age of the trail. For each hour that the
trail is cold, the DC increases by 2. The ability otherwise
follows the rules for the Track feat. Creatures tracking by scent
ignore the effects of surface conditions and poor visibility.`}</p>
    </p>
    <p>
Creatures with the scent ability can identify familiar odors just
as humans do familiar sights.
    </p>
    <p>
Water, particularly running water, ruins a trail for air-breathing
creatures. Water-breathing creatures that have the scent ability,
however, can use it in the water easily.
    </p>
    <p>
False, powerful odors can easily mask other scents. The presence
of such an odor completely spoils the ability to properly detect
or identify creatures, and the base Survival DC to track becomes
20 rather than 10.
    </p>
    <h5><a id="spell-resistance"></a>
SPELL RESISTANCE
    </h5>
    <p className="initial">
Spell resistance is the extraordinary ability to avoid being
affected by spells. (Some spells also grant spell resistance.)
    </p>
    <p>
To affect a creature that has spell resistance, a spellcaster must
make a caster level check (1d20 + caster level) at least equal to
the creature&rsquo;s spell resistance. (The defender&rsquo;s spell resistance
is like an Armor Class against magical attacks.) If the caster
fails the check, the spell doesn&rsquo;t affect the creature. The
possessor does not have to do anything special to use spell
resistance. The creature need not even be aware of the threat for
its spell resistance to operate.
    </p>
    <p>
      <p>{`Only spells and spell-like abilities are subject to spell
resistance. Extraordinary and supernatural abilities (including
enhancement bonuses on magic weapons) are not. A creature can have
some abilities that are subject to spell resistance and some that
are not. Even some spells ignore spell resistance; see When Spell
Resistance Applies, below.`}</p>
    </p>
    <p>
A creature can voluntarily lower its spell resistance. Doing so is
a standard action that does not provoke an attack of opportunity.
Once a creature lowers its resistance, it remains down until the
creatures next turn. At the beginning of the creature&rsquo;s next
turn, the creature&rsquo;s spell resistance automatically returns unless
the creature intentionally keeps it down (also a standard action
that does not provoke an attack of opportunity).
    </p>
    <p>
A creature&rsquo;s spell resistance never interferes with its own
spells, items, or abilities.
    </p>
    <p>
A creature with spell resistance cannot impart this power to
others by touching them or standing in their midst. Only the
rarest of creatures and a few magic items have the ability to
bestow spell resistance upon another.
    </p>
    <p>
Spell resistance does not stack. It overlaps.
    </p>
    <h6><a id="when-spell-resistance-applies"></a>
When Spell Resistance Applies
    </h6>
    <p className="initial">
Each spell includes an entry that indicates whether spell
resistance applies to the spell. In general, whether spell
resistance applies depends on what the spell does:
    </p><a id="spell-resistance-and-targeted-spells"></a>
    <p>
      <b>Targeted Spells</b>: Spell resistance applies if the spell is
targeted at the creature. Some individually targeted spells can be
directed at several creatures simultaneously. In such cases, a
creature&rsquo;s spell resistance applies only to the portion of the
spell actually targeted at that creature. If several different
resistant creatures are subjected to such a spell, each checks its
spell resistance separately.
    </p>
    <p><a id="spell-resistance-and-area-spells"></a>
      <b>Area Spells</b>: Spell resistance applies if the resistant
creature is within the spell&rsquo;s area. It protects the resistant
creature without affecting the spell itself.
    </p>
    <p><a id="spell-resistance-and-effect-spells"></a>
      <b>Effect Spells</b>: Most effect spells summon or create
something and are not subject to spell resistance. Sometimes,
however, spell resistance applies to effect spells, usually to
those that act upon a creature more or less directly, such as web.
    </p>
    <p>
      <p>{`Spell resistance can protect a creature from a spell that`}{`’`}{`s
already been cast. Check spell resistance when the creature is
first affected by the spell.`}</p>
    </p>
    <p>
Check spell resistance only once for any particular casting of a
spell or use of a spell-like ability. If spell resistance fails
the first time, it fails each time the creature encounters that
same casting of the spell. Likewise, if the spell resistance
succeeds the first time, it always succeeds. If the creature has
voluntarily lowered its spell resistance and is then subjected to
a spell, the creature still has a single chance to resist that
spell later, when its spell resistance is up.
    </p>
    <p>
Spell resistance has no effect unless the energy created or
released by the spell actually goes to work on the resistant
creature&rsquo;s mind or body. If the spell acts on anything else and
the creature is affected as a consequence, no roll is required.
Creatures can be harmed by a spell without being directly
affected.
    </p>
    <p>
Spell resistance does not apply if an effect fools the creature&rsquo;s
senses or reveals something about the creature.
    </p>
    <p>
Magic actually has to be working for spell resistance to apply.
Spells that have instantaneous durations but lasting results
aren&rsquo;t subject to spell resistance unless the resistant creature
is exposed to the spell the instant it is cast.
    </p>
    <p>
When in doubt about whether a spell&rsquo;s effect is direct or
indirect, consider the spell&rsquo;s school:
    </p>
    <p><a id="spell-resistance-and-abjuration"></a>
      <b>Abjuration</b>: The target creature must be harmed, changed, or
restricted in some manner for spell resistance to apply.
Perception changes aren&rsquo;t subject to spell resistance.
    </p>
    <p>
      <p>{`Abjurations that block or negate attacks are not subject to an
attacker`}{`’`}{`s spell resistance `}{`—`}{` it is the protected creature that is
affected by the spell (becoming immune or resistant to the
attack).`}</p>
    </p>
    <p><a id="spell-resistance-and-conjuration"></a>
      <b>Conjuration</b>: These spells are usually not subject to spell
resistance unless the spell conjures some form of energy. Spells
that summon creatures or produce effects that function like
creatures are not subject to spell resistance.
    </p>
    <p><a id="spell-resistance-and-divination"></a>
      <b>Divination</b>: These spells do not affect creatures directly
and are not subject to spell resistance, even though what they
reveal about a creature might be very damaging.
    </p>
    <p><a id="spell-resistance-and-enchantment"></a>
      <b>Enchantment</b>: Since enchantment spells affect creatures&rsquo;
minds, they are typically subject to spell resistance.
    </p>
    <p><a id="spell-resistance-and-evocation"></a>
      <b>Evocation</b>: If an evocation spell deals damage to the
creature, it has a direct effect. If the spell damages something
else, it has an indirect effect.
    </p>
    <p><a id="spell-resistance-and-illusion"></a>
      <b>Illusion</b>: These spells are almost never subject to spell
resistance. Illusions that entail a direct attack are exceptions.
    </p>
    <p><a id="spell-resistance-and-necromancy"></a>
      <b>Necromancy</b>: Most of these spells alter the target
creature&rsquo;s life force and are subject to spell resistance. Unusual
necromancy spells that don&rsquo;t affect other creatures directly are
not subject to spell resistance.
    </p>
    <p><a id="spell-resistance-and-transmutation"></a>
      <b>Transmutation</b>: These spells are subject to spell resistance
if they transform the target creature. Transmutation spells are
not subject to spell resistance if they are targeted on a point in
space instead of on a creature. Some transmutations make objects
harmful (or more harmful), such as magic stone. Even these spells
are not generally subject to spell resistance because they affect
the objects, not the creatures against which the objects are used.
Spell resistance works against magic stone only if the creature
with spell resistance is holding the stones when the cleric casts
magic stone on them.
    </p>
    <h6><a id="successful-spell-resistance"></a>
Successful Spell Resistance
    </h6>
    <p className="initial">
      <p>{`Spell resistance prevents a spell or a spell-like ability from
affecting or harming the resistant creature, but it never removes
a magical effect from another creature or negates a spell`}{`’`}{`s effect
on another creature. Spell resistance prevents a spell from
disrupting another spell.`}</p>
    </p>
    <p>
Against an ongoing spell that has already been cast, a failed
check against spell resistance allows the resistant creature to
ignore any effect the spell might have. The magic continues to
affect others normally.
    </p>
    <h5><a id="tremorsense"></a>
TREMORSENSE
    </h5>
    <p className="initial">
A creature with tremorsense automatically senses the location of
anything that is in contact with the ground and within range.
    </p>
    <p>
If no straight path exists through the ground from the creature to
those that it&rsquo;s sensing, then the range defines the maximum
distance of the shortest indirect path. It must itself be in
contact with the ground, and the creatures must be moving.
    </p>
    <p>
As long as the other creatures are taking physical actions,
including casting spells with somatic components, they&rsquo;re
considered moving; they don&rsquo;t have to move from place to place for
a creature with tremorsense to detect them.
    </p>
    <h5><a id="turn-resistance"></a>
TURN RESISTANCE
    </h5>
    <p className="initial">
      <p>{`Some creatures (usually undead) are less easily affected by the
turning ability of clerics or paladins.`}</p>
    </p>
    <p>
Turn resistance is an extraordinary ability.
    </p>
    <p>
When resolving a turn, rebuke, command, or bolster attempt, added
the appropriate bonus to the creature&rsquo;s Hit Dice total.
    </p>
    <h3><a id="conditions"></a>
CONDITIONS
    </h3>
    <table className="line" style={{
      "backgroundImage": "url(dot.gif)"
    }} border="0" cellPadding="0" cellSpacing="0">
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">
If more than one condition affects a character, apply them all. If
certain effects can&rsquo;t combine, apply the most severe effect.
    </p>
    <p>
      <b><a id="ability-damaged"></a>Ability Damaged</b>: The
character has temporarily lost 1 or more ability score points.
Lost points return at a rate of 1 per day unless noted otherwise
by the condition dealing the damage. A character with Strength 0
falls to the ground and is <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>. A character with
Dexterity 0 is <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a>. A character with
Constitution 0 is <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>. A character with
Intelligence, Wisdom, or Charisma 0 is <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#unconscious">unconscious</a>. Ability
damage is different from penalties to ability scores, which go
away when the conditions causing them go away.
      <p>{`Also see `}<a href="#ability-score-loss" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`Ability Score Loss`}</a>{`.`}</p>
    </p>
    <p>
      <b><a id="ability-drained"></a>Ability Drained</b>: The
character has permanently lost 1 or more ability score points. The
character can regain these points only through magical means. A
character with Strength 0 falls to the ground and is
      <a href="#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>. A
character with Dexterity 0 is <a href="#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a>. A character with
Constitution 0 is <a href="#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>. A character with
Intelligence, Wisdom, or Charisma 0 is <a href="#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>.
      <p>{`Also see `}<a href="#ability-score-loss" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`Ability Score Loss`}</a>{`.`}</p>
    </p>
    <p>
      <b><a id="blinded"></a>Blinded</b>: The character cannot see. He
takes a &#8211;2 penalty to Armor Class, loses his Dexterity bonus to AC
(if any), moves at half speed, and takes a &#8211;4 penalty on
      <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> checks and on most
Strength- and Dexterity-based skill checks. All checks and
activities that rely on vision (such as reading and
      <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
checks) automatically fail. All opponents are considered to have
total concealment (50% miss chance) to the blinded character.
Characters who remain blinded for a long time grow accustomed to
these drawbacks and can overcome some of them.
    </p>
    <p>
      <b><a id="blown-away"></a>Blown Away</b>: Depending on its size,
a creature can be blown away by winds of high velocity. A creature
on the ground that is blown away is <a href="#knocked-down" style={{
        "color": "rgb(87, 158, 182)"
      }}>knocked down</a> and rolls 1d4 x 10
feet, taking 1d4 points of nonlethal damage per 10 feet. A flying
creature that is blown away is blown back 2d6 x 10 feet and takes
2d6 points of nonlethal damage due to battering and buffering.
    </p>
    <p>
      <b><a id="checked"></a>Checked</b>: Prevented from achieving
forward motion by an applied force, such as wind. Checked
creatures on the ground merely stop. Checked flying creatures move
back a distance specified in the description of the effect.
    </p>
    <p>
      <b><a id="confused"></a>Confused</b>: A confused character&rsquo;s
actions are determined by rolling d% at the beginning of his turn:
01&ndash;10, attack caster with melee or ranged weapons (or close with
caster if attacking is not possible); 11&ndash;20, act normally; 2150,
do nothing but babble incoherently; 51&ndash;70, flee away from caster
at top possible speed; 71&ndash;100, attack nearest creature (for this
purpose, a familiar counts as part of the subject&rsquo;s self ). A
confused character who can&rsquo;t carry out the indicated action does
nothing but babble incoherently. Attackers are not at any special
advantage when attacking a confused character. Any confused
character who is attacked automatically attacks its attackers on
its next turn, as long as it is still confused when its turn
comes. A confused character does not make attacks of opportunity
against any creature that it is not already devoted to attacking
(either because of its most recent action or because it has just
been attacked).
    </p>
    <p>
      <b><a id="cowering"></a>Cowering</b>: The character is frozen in
fear and can take no actions. A cowering character takes a &#8211;2
penalty to Armor Class and loses her Dexterity bonus (if any).
    </p>
    <p>
      <b><a id="dazed"></a>Dazed</b>: The creature is unable to act
normally. A dazed creature can take no actions, but has no penalty
to AC.
      <p>{`A dazed condition typically lasts 1 round.`}</p>
    </p>
    <p>
      <b><a id="dazzled"></a>Dazzled</b>: The creature is unable to
see well because of overstimulation of the eyes. A dazzled
creature takes a &#8211;1 penalty on attack rolls,
      <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> checks, and
      <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
checks.
    </p>
    <p>
      <b><a id="dead"></a>Dead</b>: The character&rsquo;s hit points are
reduced to &#8211;10, his Constitution drops to 0, or he is killed
outright by a spell or effect. The character&rsquo;s soul leaves his
body. Dead characters cannot benefit from normal or magical
healing, but they can be restored to life via magic. A dead body
decays normally unless magically preserved, but magic that
restores a dead character to life also restores the body either to
full health or to its condition at the time of death (depending on
the spell or device). Either way, resurrected characters need not
worry about rigor mortis, decomposition, and other conditions that
affect dead bodies.
    </p>
    <p>
      <b><a id="deafened"></a>Deafened</b>: A deafened character
cannot hear. She takes a &#8211;4 penalty on initiative checks,
automatically fails <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> checks, and has a 20%
chance of spell failure when casting spells with verbal
components. Characters who remain deafened for a long time grow
accustomed to these drawbacks and can overcome some of them.
    </p>
    <p>
      <b><a id="disabled"></a>Disabled</b>: A character with 0 hit
points, or one who has negative hit points but has become
      <a href="#stable" style={{
        "color": "rgb(87, 158, 182)"
      }}>stable</a> and
conscious, is disabled. A disabled character may take a single
move action or standard action each round (but not both, nor can
she take full-round actions). She moves at half speed. Taking move
actions doesn&rsquo;t risk further injury, but performing any standard
action (or any other action the DM deems strenuous, including some
free actions such as casting a quickened spell) deals 1 point of
damage after the completion of the act. Unless the action
increased the disabled character&rsquo;s hit points, she is now in
negative hit points and dying.
    </p>
    <p>
A disabled character with negative hit points recovers hit points
naturally if she is being helped. Otherwise, each day she has a
10% chance to start recovering hit points naturally (starting with
that day); otherwise, she loses 1 hit point. Once an unaided
character starts recovering hit points naturally, she is no longer
in danger of losing hit points (even if her current hit points are
negative).
    </p>
    <p>
      <b><a id="dying"></a>Dying</b>: A dying character is
      <a href="#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>
and near death. She has &#8211;1 to &#9211;9 current hit points. A dying
character can take no actions and is unconscious. At the end of
each round (starting with the round in which the character dropped
below 0 hit points), the character rolls d% to see whether she
becomes stable. She has a 10% chance to become stable. If she does
not, she loses 1 hit point. If a dying character reaches &#8211;10 hit
points, she is dead.
    </p>
    <p>
      <b><a id="energy-drained"></a>Energy Drained</b>: The character
gains one or more negative levels, which might permanently drain
the characters levels. If the subject has at least as many
negative levels as Hit Dice, he dies. Each negative level gives a
creature the following penalties: &#8211;1 penalty on attack rolls,
saving throws, skill checks, ability checks; loss of 5 hit points;
and &#8211;1 to effective level (for determining the power, duration,
DC, and other details of spells or special abilities). In
addition, a spellcaster loses one spell or spell slot from the
highest spell level castable.
      <p>{`Alos see `}<a href="#energy-drain" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`Energy Drain and Negative
Levels`}</a>{`.`}</p>
    </p>
    <p>
      <b><a id="entangled"></a>Entangled</b>: The character is
ensnared. Being entangled impedes movement, but does not entirely
prevent it unless the bonds are anchored to an immobile object or
tethered by an opposing force. An entangled creature moves at half
speed, cannot run or <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a>, and takes a &#8211;2 penalty on
all attack rolls and a &#8211;4 penalty to Dexterity. An entangled
character who attempts to cast a spell must make a
      <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 15 + the
spell&rsquo;s level) or lose the spell.
    </p>
    <p>
      <b><a id="exhausted"></a>Exhausted</b>: An exhausted character
moves at half speed and takes a &#8211;6 penalty to Strength and
Dexterity. After 1 hour of complete rest, an exhausted character
becomes <a href="#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigued</a>. A fatigued character
becomes exhausted by doing something else that would normally
cause fatigue.
    </p>
    <p>
      <b><a id="fascinated"></a>Fascinated</b>: A fascinated creature
is entranced by a supernatural or spell effect. The creature
stands or sits quietly, taking no actions other than to pay
attention to the fascinating effect, for as long as the effect
lasts. It takes a &#8211;4 penalty on skill checks made as reactions,
such as <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> and
      <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
checks. Any potential threat, such as a hostile creature
approaching, allows the fascinated creature a new saving throw
against the fascinating effect. Any obvious threat, such as
someone drawing a weapon, casting a spell, or aiming a ranged
weapon at the fascinated creature, automatically breaks the
effect. A fascinated creature&rsquo;s ally may shake it free of the
spell as a standard action.
    </p>
    <p>
      <b><a id="fatigued"></a>Fatigued</b>: A fatigued character can
neither run nor <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a> and takes a &#8211;2 penalty to
Strength and Dexterity. Doing anything that would normally cause
fatigue causes the fatigued character to become
      <a href="#exhausted" style={{
        "color": "rgb(87, 158, 182)"
      }}>exhausted</a>.
After 8 hours of complete rest, fatigued characters are no longer
fatigued.
    </p>
    <p>
      <b><a id="flat-footed"></a>Flat-Footed</b>: A character who has
not yet acted during a combat is flat-footed, not yet reacting
normally to the situation. A flat-footed character loses his
Dexterity bonus to AC (if any) and cannot make attacks of
opportunity.
    </p>
    <p>
      <b><a id="frightened"></a>Frightened</b>: A frightened creature
flees from the source of its fear as best it can. If unable to
flee, it may fight. A frightened creature takes a &#8211;2 penalty on
all attack rolls, saving throws, skill checks, and ability checks.
A frightened creature can use special abilities, including spells,
to flee; indeed, the creature must use such means if they are the
only way to escape.
      <p>{`Frightened is like `}<a href="#shaken" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`shaken`}</a>{`, except that the creature
must flee if possible. `}<a href="#panicked" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`Panicked`}</a>{` is a more extreme state
of fear.`}</p>
    </p>
    <p>
      <b><a id="grappling"></a>Grappling</b>: Engaged in wrestling or
some other form of hand-to-hand struggle with one or more
attackers. A grappling character can undertake only a limited
number of actions. He does not threaten any squares, and loses his
Dexterity bonus to AC (if any) against opponents he isn&rsquo;t
grappling.
      <p>{`Also see the `}<a href="specialAttacks.html#grapple" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`grapple`}</a>{` rules.`}</p>
    </p>
    <p>
      <b><a id="helpless"></a>Helpless</b>: A helpless character is
      <a href="#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a>, held, bound, sleeping,
      <a href="#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>, or otherwise
completely at an opponent&rsquo;s mercy. A helpless target is treated as
having a Dexterity of 0 (&#8211;5 modifier). Melee attacks against a
helpless target get a +4 bonus (equivalent to attacking a
      <a href="#prone" style={{
        "color": "rgb(87, 158, 182)"
      }}>prone</a> target).
Ranged attacks gets no special bonus against helpless targets.
Rogues can sneak attack helpless targets.
    </p>
    <p>
As a full-round action, an enemy can use a melee weapon to deliver
a coup de grace to a helpless foe. An enemy can also use a bow or
crossbow, provided he is adjacent to the target. The attacker
automatically hits and scores a critical hit. (A rogue also gets
her sneak attack damage bonus against a helpless foe when
delivering a coup de grace.) If the defender survives, he must
make a Fortitude save (DC 10 + damage dealt) or die.
    </p>
    <p>
Delivering a coup de grace provokes attacks of opportunity.
    </p>
    <p>
Creatures that are immune to critical hits do not take critical
damage, nor do they need to make Fortitude saves to avoid being
killed by a coup de grace.
    </p>
    <p>
      <b><a id="incorporeal"></a>Incorporeal</b>: Having no physical
body. Incorporeal creatures are immune to all nonmagical attack
forms. They can be harmed only by other incorporeal creatures, +1
or better magic weapons, spells, spell-like effects, or
supernatural effects.
      <p>{`Also see `}<a href="#incorporeality" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`Incorporeality`}</a>{`.`}</p>
    </p>
    <p>
      <b><a id="invisible"></a>Invisible</b>: Visually undetectable.
An invisible creature gains a +2 bonus on attack rolls against
sighted opponents, and ignores its opponents&rsquo; Dexterity bonuses to
AC (if any). (See <a href="#invisibility" style={{
        "color": "rgb(87, 158, 182)"
      }}>Invisibility</a>, under Special
Abilities.)
    </p>
    <p>
      <b><a id="knocked-down"></a>Knocked Down</b>: Depending on their
size, creatures can be knocked down by winds of high velocity.
Creatures on the ground are knocked <a href="#prone" style={{
        "color": "rgb(87, 158, 182)"
      }}>prone</a> by the force of the wind.
Flying creatures are instead blown back 1d6 x 10 feet.
    </p>
    <p>
      <b><a id="nauseated"></a>Nauseated</b>: Experiencing stomach
distress. Nauseated creatures are unable to attack, cast spells,
concentrate on spells, or do anything else requiring attention.
The only action such a character can take is a single move action
per turn.
    </p>
    <p>
      <b><a id="panicked"></a>Panicked</b>: A panicked creature must
drop anything it holds and flee at top speed from the source of
its fear, as well as any other dangers it encounters, along a
random path. It can&rsquo;t take any other actions. In addition, the
creature takes a &#8211;2 penalty on all saving throws, skill checks,
and ability checks. If cornered, a panicked creature
      <a href="#cowering" style={{
        "color": "rgb(87, 158, 182)"
      }}>cowers</a> and does
not attack, typically using the total defense action in combat. A
panicked creature can use special abilities, including spells, to
flee; indeed, the creature must use such means if they are the
only way to escape.
      <p>{`Panicked is a more extreme state of fear than `}<a href="#shaken" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`shaken`}</a>{` or`}</p>
      <a href="#frightened" style={{
        "color": "rgb(87, 158, 182)"
      }}>frightened</a>.
    </p>
    <p>
      <b><a id="paralyzed"></a>Paralyzed</b>: A paralyzed character is
frozen in place and unable to move or act. A paralyzed character
has effective Dexterity and Strength scores of 0 and is
      <a href="#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>, but
can take purely mental actions. A winged creature flying in the
air at the time that it becomes paralyzed cannot flap its wings
and falls. A paralyzed swimmer can&rsquo;t swim and may drown. An
creature can move through a space occupied by a paralyzed creature
ally or not. Each square occupied by a paralyzed creature,
however, counts as 2 squares.
    </p>
    <p>
      <b><a id="petrified"></a>Petrified</b>: A petrified character
has been turned to stone and is considered <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#unconscious">unconscious</a>.
If a petrified character cracks or breaks, but the broken pieces
are joined with the body as he returns to flesh, he is unharmed.
If the character&rsquo;s petrified body is incomplete when it returns to
flesh, the body is likewise incomplete and there is some amount of
permanent hit point loss and/or debilitation.
    </p>
    <p>
      <b><a id="pinned"></a>Pinned</b>: Held immobile (but not
      <a href="#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>) in a
      <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>.
    </p>
    <p>
      <b><a id="prone"></a>Prone</b>: The character is on the ground.
An attacker who is prone has a 4 penalty on melee attack rolls
and cannot use a ranged weapon (except for a crossbow). A defender
who is prone gains a +4 bonus to Armor Class against ranged
attacks, but takes a &#8211;4 penalty to AC against melee attacks.
      <p>{`Standing up is a move-equivalent action that provokes an attack of
opportunity.`}</p>
    </p>
    <p>
      <b><a id="shaken"></a>Shaken</b>: A shaken character takes a &#8211;2
penalty on attack rolls, saving throws, skill checks, and ability
checks.
      <p>{`Shaken is a less severe state of fear than `}<a href="#frightened" style={{
          "color": "rgb(87, 158, 182)"
        }}>{`frightened`}</a>{` or`}</p>
      <a href="#panicked" style={{
        "color": "rgb(87, 158, 182)"
      }}>panicked</a>.
    </p>
    <p>
      <b><a id="sickened"></a>Sickened</b>: The character takes a &#8211;2
penalty on all attack rolls, weapon damage rolls, saving throws,
skill checks, and ability checks.
    </p>
    <p>
      <b><a id="stable"></a>Stable</b>: A character who was
      <a href="#dying" style={{
        "color": "rgb(87, 158, 182)"
      }}>dying</a> but who has
stopped losing hit points and still has negative hit points is
stable. The character is no longer dying, but is still
      <a href="#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a>.
If the character has become stable because of aid from another
character (such as a <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a> check or magical healing),
then the character no longer loses hit points. He has a 10% chance
each hour of becoming conscious and <a href="#disabled" style={{
        "color": "rgb(87, 158, 182)"
      }}>disabled</a> (even though his hit
points are still negative).
    </p>
    <p>
If the character became stable on his own and hasn&rsquo;t had help, he
is still at risk of losing hit points. Each hour, he has a 10%
chance of becoming conscious and disabled. Otherwise he loses 1
hit point.
    </p>
    <p>
      <b><a id="staggered"></a>Staggered</b>: A character whose
nonlethal damage exactly equals his current hit points is
staggered. A staggered character may take a single move action or
standard action each round (but not both, nor can she take
full-round actions).
    </p>
    <p>
A character whose current hit points exceed his nonlethal damage
is no longer staggered; a character whose nonlethal damage exceeds
his hit points becomes unconscious.
    </p>
    <p>
      <b><a id="stunned"></a>Stunned</b>: A stunned creature drops
everything held, can&rsquo;t take actions, takes a &#8211;2 penalty to AC, and
loses his Dexterity bonus to AC (if any).
    </p>
    <p>
      <b><a id="turned"></a>Turned</b>: Affected by a turn undead
attempt. Turned undead flee for 10 rounds (1 minute) by the best
and fastest means available to them. If they cannot flee, they
cower.
    </p>
    <p>
      <b><a id="unconscious"></a>Unconscious</b>: Knocked out and
      <a href="#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>. Unconsciousness can
result from having current hit points between &#8211;1 and &#8211;9, or from
nonlethal damage in excess of current hit points.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      